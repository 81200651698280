import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  background-color: #e9dfd0;

  @media (max-width: 1023px) {
    padding-top: 40px;
    padding-bottom: 10px;
  }

  @media (min-width: 1024px) {
    height: 785px;
    padding-top: 100px;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1087px;
  padding: 0 24px;
`;

export const Title = styled.h4`
  margin-bottom: 23px;
  color: #141f35;
  font-family: 'GreatWestern';
  font-size: 32px;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    text-align: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;

  @media (max-width: 1023px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Form = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;

  @media (max-width: 1023px) {
    max-width: 440px;
  }

  @media (min-width: 1024px) {
    max-width: 663px;
  }
`;

export const StampImg = styled.img`
  position: absolute;

  @media (max-width: 1023px) {
    top: 350px;
    left: -70px;
    width: 128px;
  }

  @media (min-width: 1024px) {
    top: 245px;
    left: -122px;
  }
`;

export const InputGroup = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Input = styled.input`
  height: 47px;
  padding: 0 16px;
  font-size: 18px;
  border: 0 none;

  @media (max-width: 1023px) {
    width: 100%;

    & + & {
      margin-top: 20px;
    }
  }

  @media (min-width: 1024px) {
    width: calc(50% - 10px);
  }

  &::placeholder {
    color: #ddd;
  }
`;

export const Textarea = styled.textarea`
  position: relative;
  width: 100%;
  padding: 16px;
  font-size: 18px;
  border: 0 none;

  &::placeholder {
    color: #ddd;
  }
`;

export const Button = styled.button`
  position: relative;
  height: 42px;
  margin-top: 20px;
  padding: 0 16px;
  color: #141f35;
  font-family: 'DharmaGothic';
  font-size: 22px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  border: 1px solid #141f35;
`;

export const Info = styled.div`
  position: relative;
  color: #141f35;

  @media (max-width: 1023px) {
    width: 100%;
    max-width: 440px;
    margin-top: 30px;
    font-size: 14px;
    line-height: 22px;
  }

  @media (min-width: 1024px) {
    margin-left: 60px;
    font-size: 16px;
    line-height: 28px;
  }
`;

export const InfoTitle = styled.div`
  font-weight: 900;
`;

export const InfoText = styled.div`
  margin-bottom: 32px;
`;

export const Status = styled.span`
  position: absolute;
  top: -20px;
  left: 0;
  color: #d79d5c;
  font-size: 11px;
  font-weight: bold;
`;