import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import {
  Root,
  Container,
  Title,
  Wrapper,
  Form,
  StampImg,
  InputGroup,
  Input,
  Textarea,
  Button,
  Info,
  InfoTitle,
  InfoText,
  Status
} from "./GetInTouch.style";

import StampSrc from "../../images/stamp-beige.svg";

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const GetInTouch = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formValues, setFormValues] = useState({});
  const [formStatus, setFormStatus] = useState("");

  const handleChange = e => {
    const newFormValues = {...formValues };
    const { name, value } = e.target;
    newFormValues[name] = value;
    // console.info({newFormValues})
    setFormValues(newFormValues);
  }

  const handleSubmit = async e => {
    e.preventDefault();
    if ([undefined, ""].includes(formValues?.name)) {
      setFormStatus("Invalid name.");
      return;
    }
    if (!emailPattern.test(formValues?.from || "")) {
      setFormStatus("Invalid Email.");
      return;
    }
    if ([undefined, ""].includes(formValues?.message)) {
      setFormStatus("Invalid message.");
      return;
    }
    if (!executeRecaptcha) {
      setFormStatus("Invalid reCAPTCHA. Please try again.");
      return;
    }
    const captchaToken = await executeRecaptcha("contact");

    try {
      setFormStatus("Submitting...");
      const response = await fetch(
        `https://${process.env.GATSBY_API_DOMAIN}/contact`,
        {
          method: "POST",
          body: JSON.stringify({ ...formValues, captchaToken }),
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      // console.info({ response });
      setFormValues({});
      setFormStatus("Contact form successfully submitted!");
    } catch (err) {
      const errorJson = err.responseJSON;
      if (
        ["INVALID_CAPTCHA", "INVALID_CAPTCHA_SCORE"].includes(errorJson.type)
      ) {
        setFormStatus("Invalid captcha. Please try again.");
      } else {
        setFormStatus("An error occurred. Please try again.");
      }
    }
  };

  const handleFocus = () => {
    const recaptchaEl = document.getElementsByClassName("grecaptcha-badge");
    recaptchaEl[0].style.visibility = "visible";
  };

  return (
    <Root>
      <Container>
        <Title>Get in touch</Title>
        <Wrapper>
          <Form onSubmit={handleSubmit}>
            <StampImg src={StampSrc} alt="" />
            <Status>{formStatus}</Status>
            <InputGroup>
              <Input type="text" name="name" placeholder="Name" value={formValues.name || ""} onChange={handleChange} onFocus={handleFocus} />
              <Input type="text" name="from" placeholder="Email Address" value={formValues.from || ""} onChange={handleChange} onFocus={handleFocus} />
            </InputGroup>
            <Textarea name="message" rows="10" placeholder="Message" value={formValues.message || ""} onChange={handleChange} onFocus={handleFocus}></Textarea>
            <Button type="submit">Submit</Button>
          </Form>

          <Info>
            <InfoTitle>Customer Service Line</InfoTitle>
            <InfoText>
              865-436-6995
              <br />
              MON - FRI: 12PM - 8PM
              <br />
              *hours may vary depending on federal holiday
              <br />
              customerservice@osdistillery.com
            </InfoText>

            <InfoTitle>Ole Smoky Distillery</InfoTitle>
            <InfoText>
              903 Parkway, Unit 128
              <br />
              Gatlinburg, TN 37738
              <br />
              MON - THURS: 10AM - 10PM
              <br />
              FRI - SUN: 10AM - 11PM
            </InfoText>
          </Info>
        </Wrapper>
      </Container>
    </Root>
  );
};

export default GetInTouch;
