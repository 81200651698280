import React from "react";

import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import GetInTouch from '../components/GetInTouch/GetInTouch';
import Footer from '../components/Footer/Footer';

import BgDesktopSrc from '../images/hero-fire-bg-desktop.jpg';
import BgMobileSrc from '../images/hero-fire-bg-mobile.jpg';

const GetInTouchPage = () => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', minHeight: '100%' }}
    >
      <Header />
      <Hero
        img={{ mobile: BgMobileSrc, desktop: BgDesktopSrc }}
        title="get&nbsp;in&nbsp;touch"
      />
      <GetInTouch />
      <Footer />
    </div>
  );
};

export default GetInTouchPage;
